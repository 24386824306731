.footermaindiv {
    /* background: var(--whitecolor); */
    background-color: #F9EAF7;
    color: var(--purplecolor);
    padding-inline: 10rem;
    padding-block: 7rem;
    /* margin-top: 4rem; */
    height: 360px;
}
.loginfooter{

}
.mainflexdivoffooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.maintwodivparent {
    display: flex;
    gap: 5rem;
}

.imgandlogodiv {
    display: flex;
    gap: 1rem;
    align-items: center;
    white-space: nowrap;
}

.imgandlogodiv img {
    /* width: 220px; */
    height: 70px;
}


.imgandlogodiv h2{
    font-weight: 400;
    font-size: 4rem;
    font-family: "OldAnticDecorativeRegular";
}

.personlogoandimgdiv {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    gap: 1rem;
cursor: pointer;
}

.personlogoandimgdiv svg{
    font-size: 12px;
}

.personlogoandimgdiv img {
    width: 15px;
}

.personlogoandimgdiv, .ulandpicmaindiv {
    padding-top: 2rem;
}

.personlogoandimgdiv p {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ulandpicmaindiv p {
    font-size: 1.5rem;
}

.lastlinks,
.persondiv {
    text-align: end;
}

.persondiv h4,
.lastlinks h4 {
    font-size: 2.4rem;
    font-weight: 800;
    font-family: "AvenirRegular";

}

.footerlastp {
    text-align: center;
    color: var(--purplecolor);
    padding-top: 8rem;
    font-size: 1.8rem;
    font-family: "Cairo";
    font-weight: 600;
    
}

.ulandpicmaindiv {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
    cursor: pointer;
}


.ulandpicmaindiv img{
    width: 9px;
    
}

.iconsdiv {
    display: flex;
    justify-content: center;
    gap: 1rem;
    height: 100px;
    font-size: 3rem;
    margin-top: 2rem;
    margin-bottom: 10px;
    color: #fff;
}

.iconsdiv .footer_icons {
    background-color: #1C1C1E;
    font-size: 2rem;
    padding: 1rem;
    border-radius: 100%;
    cursor: pointer;
}

.iconificons{
    font-size: 33px !important;
}
/* Media query for responsiveness */
@media (max-width: 768px) {
    .footermaindiv {
        padding-inline: 2rem;
        height: auto;
        padding-bottom: 2rem;
    }

    .mainflexdivoffooter {
        gap: 2rem;
        padding-inline: 1rem;
    }

    .ufooterlinks {
        padding: 0;
        margin: 0;
    }

    .maintwodivparent {
        justify-content: center;
        gap: 2rem;
    }

    .imgandlogodiv h1 {
        font-size: 2rem;
        text-align: center;
    }

    .persondiv,
    .lastlinks {
        text-align: end;
    }

    .personlogoandimgdiv,
    .ulandpicmaindiv {
        justify-content: end;
    }

    .persondiv h4,
    .lastlinks h4 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .footermaindiv {
        padding-inline: 1rem;
        padding-block: 1rem;
        /* position: absolute;
        bottom: 0; */
        width: 100%;
    }

    .imgandlogodiv h2{
        font-size: 25px;
    }
    
    .imgandlogodiv img {
        width: 40px;
        height: 40px;
    }

    .imgandlogodiv h1 {
        font-size: 3rem;
    }

    .personlogoandimgdiv img {
        width: 16px;
    }

    .persondiv h4,
    .lastlinks h4 {
        font-size: 16px;
    }

    .personlogoandimgdiv p {
        font-size: 18px;
    }
    
    .lastlinks p,
    .footerlastp {
        font-size: 1.1rem;
        padding-top: 40px;
    }



    /* .footerlastp {
        padding-top: 2rem;
    } */
    .personlogoandimgdiv, .ulandpicmaindiv{
        padding-top: 3rem;
    }
    .imgandlogodiv {
        /* display: block; */
    }
    .maintwodivparent{
        gap: 10px;
    }
    .iconsdiv {
       gap: 5px;
       height: 30px;
    }

    .footerlastp{
        font-size: 14px;
    }
    .iconsdiv .footer_icons{
        font-size: 1rem;
    padding: 0.6rem;
    }
    .iconificons{
        font-size: 18px !important;
    }
    .personlogoandimgdiv{
        padding-top: 1rem;
    }
}

@media (max-width: 400px) {
    .footermaindiv {
        padding-inline: 0.5rem;
        padding-block: 1rem;
    }

    .imgandlogodiv img {
        width: 40px;
    }
    .imgandlogodiv h2{
        font-size: 25px;
    }
    .imgandlogodiv h1 {
        font-size: 2.5rem;
    }

    .personlogoandimgdiv img {
        width: 16px;
    }

    .persondiv h4,
    .lastlinks h4 {
        font-size: 14px;
    }
    .heightmaindivlogin{
        height: auto;
    }
    .personlogoandimgdiv, .ulandpicmaindiv{
        padding-top: 0.5rem;
    }
    .persondiv p,
    .lastlinks p,
    .footerlastp {
        font-size: 12px;
    }

    .footerlastp {
        padding-top: 2rem;
    }

    .imgandlogodiv {
        /* display: block; */
    }

    .iconsdiv {
       
    }
    .iconificons{
        font-size: 18px !important;
    }
    .personlogoandimgdiv{
        padding-top: 1rem;
    }
}

@media (max-width: 360px) {
    .loginandsignupbutton {
        margin-inline: 1rem;
    }

}

@media (max-width: 320px) {
    .footermaindiv {
        padding-inline: 0.2rem;
        padding-block:1rem;
    }
    .personlogoandimgdiv{
        padding-top: 1rem;
    }
    .imgandlogodiv h2{
        font-size: 25px;
    }
    .imgandlogodiv img {
        width: 40px;
    }

    .imgandlogodiv h1 {
        font-size: 2rem;
    }

    .personlogoandimgdiv img {
        width: 16px;
    }

    .persondiv h4,
    .lastlinks h4 {
        font-size: 12px;
    }

    .persondiv p,
    .lastlinks p,
    .footerlastp {
        font-size: 0.9rem;
    }

    .footerlastp {
        padding-top: 40px;
    }

    .imgandlogodiv {
        /* display: block; */
    }

    .iconsdiv {
        display: none;
    }
}
